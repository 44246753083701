import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { contentExists, renderFormattedText, formatDate, getCrumbsObj } from "../utils/ug-utils"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Button from "../components/button"
import Grid from "../components/grid"
import GridCell from "../components/gridCell"
import SavingsTeaser from "../components/savingsTeaser"
import Bulletlink from "../components/bulletLink"

import "../styles/savings.scss"
import Breadcrumbs from "../components/breadcrumbs"

const SavingsPage = ({ data, pageContext }) => {
  const crumbsObj = getCrumbsObj(pageContext)
  const { images, savings, relatedSavings, drupal_internal__nid } = data
  const { title, body, field_metatags, path } = savings.edges[0].node
  const imageData = images.edges
  const ogDescription = contentExists(field_metatags) ? field_metatags.og_description : null
  const ogImage = contentExists(imageData)
    ? imageData[0].node.relationships.field_media_image.localFile.publicURL
    : null
  const ogImageAlt = contentExists(imageData) ? imageData[0].node.field_media_image.alt : null
  const fieldButtonsData = savings.edges[0].node.relationships.field_widgets[0]?.relationships.field_buttons;
  const columnClass = "section-button-main";
  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    992: 2,
    576: 1
  };
  
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "alumni-savings-page",
          "aria-label": "Alumni Savings Page"
        }}
      />
      <Seo title={title} description={ogDescription} img={ogImage} imgAlt={ogImageAlt} />

      {/**** Header and Title ****/}
      <div id="rotator" className="shorter-hero">
        <Hero imgData={imageData} />
        <div className="container ft-container">
          <div className="savings-content-area">
            <Breadcrumbs targetUrl="/special-offers" activeTitle={title} />
            <h1 className="fancy-title mt-5" aria-label={`Special Offer: ${title}`}>Special Offer: {title}</h1>
          </div>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Row className="row-with-vspace site-content alumni-savings alumni-savings-full" as="article">
            <Col sm={4} className="savings-page-content">
              <React.Fragment>
                <GatsbyImage
                  image={savings.edges[0].node.relationships.field_image.localFile.childImageSharp.gatsbyImageData}
                  alt={savings.edges[0].node.field_image.alt || ""}
                />
              </React.Fragment>
            </Col>
            <Col sm={5} className="content-area">
              {/**** Body content ****/}
              <div className="saving-details">
                <h2 className="mb-3">DETAILS</h2>
                {renderFormattedText(body)}
                {fieldButtonsData && fieldButtonsData.length > 0 &&
                <>
                  {fieldButtonsData.map((buttonData) => {
                    return Button(buttonData, columnClass, "", "", "Shop Offer")
                  })}
                </>
                }
              </div>
            </Col>
            {/* <Bulletlink uri="#">Submit Your Promotion</Bulletlink> */}
            {/* <Col sm={3} className = "MobileFullCover">
              <div className="quick-links content-area">
                <h2>QUICK LINKS</h2>
                <Bulletlink uri="/special-offers">All Offers</Bulletlink>
              </div>
            </Col> */}
          </Row>
          
          <Row className="row-with-vspace site-content card-collection-wrapper">
            <Col md={12} className="content-area">
              <Container>
                <h2 className="mt-5 mb-4">OFFERS SIMILAR TO THIS</h2>
                {relatedSavings.edges.length > 0 ? (
                  <Grid breakpointCols={breakpointColumnsObj}>
                    {relatedSavings.edges
                      .filter(({ node }) => savings.edges.length === 0 || savings.edges.every(({ node: savingsNode }) => savingsNode.id !== node.id))
                      .slice(0, 4)
                      .map(({ node }) => (
                        <GridCell key={node.id}>
                          <SavingsTeaser node={node} className="masonry-card" />
                        </GridCell>
                      ))}
                  </Grid>
                ) : (
                  <p>No related savings to display.</p>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SavingsPage

export const query = graphql`
  fragment Savings on node__alumni_savings {
    ...Node
    drupal_internal__nid
    path {
      alias
    }
    body {
      processed
      summary
    }
    drupal_id
    field_call_to_action_link {
      title
      uri
    }
    field_image {
      alt
    }
    field_detail_image {
      alt
    }
    relationships {
      field_widgets {
        ... on paragraph__section_buttons {
          id
          field_buttons {
            drupal_internal__target_id
          }
          relationships {
            field_section_column {
              name
            }

            field_buttons{
              drupal_id
              field_button_link {
                title
                full_url
                uri
              }
              field_cta_heading {
                processed
              }
              field_font_awesome_icon
              field_font_awesome_icon_position
              field_formatted_title {
                processed
              }
              relationships {
                field_button_style {
                  name
                }
                field_font_awesome_icon_colour {
                  name
                }
                field_cta_analytics_goal {
                  name
                  field_goal_action
                }
              }
            }
          }
        }
      }
      field_type {
        ... on taxonomy_term__savings_type {
          id
          name
          drupal_internal__revision_id
        }
      }
      field_locations {
        ... on taxonomy_term__locations {
          id
          name
        }
      }
      field_hero_image {
        ...Image
      }
      field_image {
        uri {
          url
        }
        localFile {
          childImageSharp {
            gatsbyImageData
            _card_image: gatsbyImageData(
              width: 500
              height: 415
              transformOptions: { fit: CONTAIN }
              backgroundColor: "white"
              placeholder: BLURRED
            )
          }
        }
      }
      field_detail_image {
        uri {
          url
        }
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
  query ($id: String $typeId: Int) {
    savings: allNodeAlumniSavings(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...Savings
        }
      }
    }
    relatedSavings: allNodeAlumniSavings(filter: { field_type: { elemMatch: { drupal_internal__target_id: { eq: $typeId  } } } }) {
      edges {
        node {
          ...Savings
          id
          field_type {
            drupal_internal__target_id
          }
          title
        }
      }
    }
    images: allMediaImage(filter: { relationships: { node__alumni_savings: { elemMatch: { id: { eq: $id } } } } }) {
      edges {
        node {
          ...HeroImage
        }
      }
    }
  }
`
